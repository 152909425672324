import { app } from '../initApp';
import { loadCanvasDocument$ } from './actions/loadCanvasDocument$';
import { UseCase } from './usecase/UseCase';
import global from '../../gui/services/global';
import { enumDecorationAreaIds, getDecorationAreasBrushes, getUniqueBrushes } from './actions/canvas';
import { report } from '../../gui/util/rollbar';
import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { hasProducts } from '../domain/Login/Conditions';
import { Condition } from '../screen/Condition';

export interface GetColorsInUseByProductOptions {
  products: any;
  activeItemNumber: string;
}

export const getColorsInUseByProductUseCase: UseCase = {
  type: 'GET_COLORS_IN_USE_BY_PRODUCT',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [hasProducts]);
  },

  run: async (options: GetColorsInUseByProductOptions) => {
    let arr = [];
    const canvasId = global.__MAIN_CANVAS_ID__;
    const length = options.products?.length;
    const activeProductIndex = options.products?.map(p => p.ItemNumber).indexOf(options.activeItemNumber);
    const canvas = CanvasAreaFactory.getCanvasById(canvasId);
    
    // Move the selected product (active) to the end of the array to load its canvas and roster correctly
    const item = options.products?.splice(activeProductIndex, 1)[0];
    options.products?.splice((length - 1), 0, item);

    for (const product of options.products) {
      await loadCanvasDocument$(canvasId, product.SavedDocId)
        .then((res) => {
          const decorationAreas = enumDecorationAreaIds(canvas);
          const colorZonesBrushes = getUniqueBrushes(canvasId);
          const daBrushes = getDecorationAreasBrushes(canvasId, decorationAreas);
          const uniqueBrushes = colorZonesBrushes.concat(daBrushes);

          arr.push({
            uniqueBrushes: uniqueBrushes,
            docId: res.docId
          });
        });
    }

    return Cx.resolve(arr);
  }
};

/**
 * @category Use Case
 */
export async function getColorsInUseByProduct(options: GetColorsInUseByProductOptions) {
  try {
    await app.runUseCase(getColorsInUseByProductUseCase, options);
  }
  catch (err) {
    report(err);
  }
}