import { RootState } from '../Application';
import { isConfigReady } from '../domain/Config/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';
import { getUpsRates$ } from './actions/getUpsRates$';

export interface getUpsRatesOptions {
  ShipTo: any
  , NumberOfJerseys: number
  , PartnersKey: string
  liveRates: boolean,
  Products: any
}

export const getUpsRatesUseCase: UseCase = {
  type: 'GET_UPS_RATES',

  checkConditions: (state: RootState) => {
    return Condition.evaluate(state, [isConfigReady]);
  },

  run: (options: getUpsRatesOptions) => {
    if (options.NumberOfJerseys === 0) {
      return Promise.resolve([{
        Code: "N/A",
        Name: "N/A",
        Cost: 0,
        Threshold: { value: 0, type: "Type" }
      }]);
    }
    
    return getUpsRates$(options.ShipTo, options.NumberOfJerseys, options.PartnersKey, options.liveRates, options.Products)
      .then((rates) => {
        return Cx.resolve(rates);
      })
  }
};

export function canGetUpsRates(state: RootState) {
  return getUpsRatesUseCase.checkConditions(state).isAllowed;
}

export function getUpsRates(options: getUpsRatesOptions) {
  app.runUseCase(getUpsRatesUseCase, options);
}
