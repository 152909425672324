import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import { AuthMethod } from '../../../../app/reducers/LoginReducer';
import { LoginCreateUser } from './LoginCreateUser';
import { LoginMessage } from './LoginMessage';
import { LoginSignIn } from './LoginSignIn';
import PhoneVerification from './PhoneVerification';
import { SelectVerification } from './SelectVerification';
import { isEqual } from 'lodash';
import { verifyEmailHash } from '../../../../app/usecases/login/verifyEmail';
import styles from './Login.module.css';

interface Props {
  partner: any;
  createUser?: boolean;
  loginSuccess?: boolean;
  promptForSelectVerification?: boolean;
  requestingAccessBy?: AuthMethod.Email | AuthMethod.Phone | AuthMethod.Google;
  token: string;
  email?: string;
  phone?: string;
  inSystem?: boolean;
  requestFailed?: boolean;
  requestInProgress?: boolean;
  errorMessage?: string;
  partnerInfo?: any;
  verificationError?: string;
}

const Login = (props: Props) => {
  const [verificationCode, setVerificationCode] = useState('');

  const handleVerifyCode = () => {

    const verifyOptions = {
      hash: verificationCode,
      email: props.email || '',
      partner: props.partner.partnersKey || '',
    };

    verifyEmailHash(verifyOptions)
  };

  if (props.promptForSelectVerification)
    return (
      <SelectVerification
        email={props.email}
        phone={props.phone}
        partnerInfo={props.partnerInfo}
        token={props.token}
      />
    );

  if (isEqual(props.requestingAccessBy, AuthMethod.Email) && props.inSystem)
    return (
      <LoginMessage goBack={false}>
        <React.Fragment>
          <div className={styles.planeIcon + ' fas fa-paper-plane'}></div>
          <div className={styles.loginMainTitle + ' partner-color2'}>Check your email!</div>
          <div className={styles.linkSentMessage}>
            To login password-free, tap the button in the email we just sent to <b>{props.email}</b>.
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="verificationCode" className={styles.inputLabel}>
              Enter the verification code:
            </label>
            <br />
            <input
              type="text"
              id="verificationCode"
              name="verificationCode"
              className={styles.inputField}
              placeholder="Enter code here"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </div>
          {props.errorMessage &&             
            <div>
              <div className={styles.errorMessageContainer}>
                <div className={styles.errorTitle}>Verification failed!</div>
                <div className={styles.errorMessage}>{props.errorMessage}</div>
              </div>
            </div>}
          <button className={styles.loginButton + ' partner-color1 partner-border-color1'} onClick={handleVerifyCode} disabled={ verificationCode.length == 0 }>
            Verify Code
          </button>
        </React.Fragment>
      </LoginMessage>
    );

  if (isEqual(props.requestingAccessBy, AuthMethod.Phone) && props.inSystem)
    return <PhoneVerification />;

  if (props.createUser)
    return (
      <LoginCreateUser
        email={props.email}
        phone={props.phone}
        token={props.token}
        partner={props.partner}
        errorMessage={props.errorMessage}
      />
    );

  return (
    <LoginSignIn
      requestFailed={props.requestFailed}
      requestInProgress={props.requestInProgress}
      token={props.token}
      email={props.email}
      errorMessage={props.errorMessage}
      partnerInfo={props.partnerInfo}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const partnerName = state.GUI.partnerData && state.GUI.partnerData.name;
  const supportEmail = state.GUI.partnerData && (state.GUI.partnerData.supportEmail || state.GUI.partnerData.email);
  const supportPhone = state.GUI.partnerData && (state.GUI.partnerData.supportPhone || state.GUI.partnerData.phone);
  const verificationError = state.Coach.verificationError?.errorMessage ;

  return {
    createUser: !state.Login.inSystem && state.Login.lastRequestForAccess > 0 && !state.Login.requestFailed,
    promptForSelectVerification: state.Login.promptForSelectVerification,
    requestingAccessBy: state.Login.requestingAccessBy,
    inSystem: state.Login.inSystem,
    email: state.Login.email,
    phone: state.Login.phone,
    requestInProgress: state.Login.requestInProgress,
    errorMessage: state.Login.errorMessage || verificationError,
    partnerInfo: {
      partnerName: partnerName,
      partnerLogo: state.GUI.partnerLogoUrl,
      supportPhone: supportPhone,
      supportEmail: supportEmail,
    },
    partner: { partnersKey: state.Session.partnersKey },
  };
};

export default connect(mapStateToProps, null)(Login);
