import { backendClient } from '../../gui/services/backend';
import { hasCoachesKey, coachHasSession, hasPartnerKey } from '../domain/Login/Conditions';
import { hasValidRosterItems } from '../domain/Order/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getOrdersByCoachesKey$ } from './actions/getOrdersByCoachesKey$';
import { UseCase } from './usecase/UseCase';

export interface SubmitOrderUseCaseOptions {
  coachesKey: string;
  partnersKey: string;
  originallyCopiedFrom?: string;
  customerInfoKey: string;
  orderState: any;
  orderStateSnippet: any;
}

const fixRosterFields = (orderState) => {
  orderState.forEach((state) => {
    const { RosterFieldMap, RosterItems } = state;
  
    RosterFieldMap.forEach((field) => {
      const { RosterField } = field;
  
      RosterItems.forEach((item) => {
        if (!item.hasOwnProperty(RosterField)) {
          item[RosterField] = '';
        }
      });
    });
  });
}

export const submitOrderUseCase: UseCase = {
  type: 'SUBMIT_ORDER',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [hasCoachesKey, coachHasSession, hasPartnerKey, hasValidRosterItems]);
  },

  run: ( options: SubmitOrderUseCaseOptions ) => {
    const coachesKey = options.coachesKey;
    const customerInfoKey = options.customerInfoKey;
    const orderState = options.orderState;
    const orderStateSnippet = options.orderStateSnippet; 

    fixRosterFields(orderState);

    if (orderStateSnippet.storeLocation)
    {
      orderStateSnippet.partnerInfo.address1     = orderStateSnippet.storeLocation.address1 ;
      orderStateSnippet.partnerInfo.address2     = orderStateSnippet.storeLocation.address2 ;
      orderStateSnippet.partnerInfo.city         = orderStateSnippet.storeLocation.city ;
      orderStateSnippet.partnerInfo.state        = orderStateSnippet.storeLocation.state ;
      orderStateSnippet.partnerInfo.postalCode   = orderStateSnippet.storeLocation.postalCode ;
      orderStateSnippet.partnerInfo.salesTaxType = orderStateSnippet.storeLocation.salesTaxType ;
    }

    return backendClient.post<any>({
      endPoint: 'orders',
      method: 'addNewOrder',
      payload: {
        'coachesKey': coachesKey,
        'orderState': orderState,
        'orderStateSnippet': orderStateSnippet,
        'partnersKey': options.partnersKey,
        'originallyCopiedFrom': options.originallyCopiedFrom,
        'customerInfoKey' : customerInfoKey
      }
    })
    .then(() => {
      return getOrdersByCoachesKey$(coachesKey, options.partnersKey)
      .then((data) => {
        return Cx.resolve({
          submittedOrders: data
        });
      });
    })
    .catch((response: any) => {
      Cx.reject();
    })
  }
};

/**
 * @category Can Use Case
 */
export function canSubmitOrder(state): boolean {
  return submitOrderUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function submitOrder( options: SubmitOrderUseCaseOptions ) {
  app.runUseCase( submitOrderUseCase, options );
}
