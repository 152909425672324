import * as App_ from 'lodash';
import { combineReducers, createStore } from 'redux';
import { RosterItem } from './domain/Roster/RosterItem';
import { monitorStore } from './monitorStore';
import { AppReducer, AppReducerState } from './reducers/AppReducer';
import { ConfigState, ErrorState } from './reducers/Base';
import { CanvasFullStateReducer, CanvasFullStateReducerState } from './reducers/CanvasFullStateReducer';
import { Thumb3DReducer, Thumb3DReducerState } from './reducers/Thumb3DReducer';
// import { CoachState } from './reducers/CoachReducer';
import { ConfigReducer } from './reducers/Config';
import { ErrorHandlerReducer } from './reducers/ErrorHandler';
import { FontReducer, FontReducerState } from './reducers/FontReducer';
import { CoachReducer, CoachState } from './reducers/CoachReducer';
import { PatternReducer, PatternReducerState } from './reducers/PatternReducer';
import { PhoneVerificationReducer, PhoneVerificationState } from './reducers/PhoneVerificationReducer';
import { GuiReducer, GuiReducerState } from './reducers/GuiReducer';
import { LoginReducer, LoginState } from './reducers/LoginReducer'
import { ModalReducer, ModalReducerState } from './reducers/ModalReducer';
import { OrderReducer, OrderReducerState } from './reducers/OrderReducer';
import { PollingReducer } from './reducers/PollingReducer';
import { SavedOrdersReducer, SavedOrdersReducerState } from './reducers/SavedOrdersReducer';
import { SessionReducer, SessionReducerState } from './reducers/SessionReducer';
import { SubmittedOrdersReducer, SubmittedOrdersReducerState } from './reducers/SubmittedOrdersReducer';
import { UseCase } from './usecases/usecase/UseCase';
import { UseCaseResult, UseCaseResultType } from './usecases/usecase/UseCaseResult';
import { RejectionReason, RejectKey } from './utility/RejectionReason';
import { initBackgroundUseCaseTriggers } from './triggers/initBackgroundUseCaseTriggers'
import { showCustomMsgPopup } from './usecases/customMsgPopupUseCase'
import { showLogin } from './initApp';

declare const Cx: any;

export interface RootState {
  App: AppReducerState;
  Coach: CoachState;
  Config: ConfigState;
  CanvasFullState: CanvasFullStateReducerState;
  Error: ErrorState;
  Fonts: FontReducerState;
  GUI: GuiReducerState;
  Login: LoginState;
  Modal: ModalReducerState;
  Order: OrderReducerState;
  Patterns: PatternReducerState;
  PhoneVerification: PhoneVerificationState,
  Polling: any;
  SavedOrders: SavedOrdersReducerState;
  Session: SessionReducerState;
  SubmittedOrders: SubmittedOrdersReducerState;
  Thumb3D: Thumb3DReducerState;
}

export function Application(){

  const rootReducer = combineReducers({
    App: AppReducer,
    CanvasFullState: CanvasFullStateReducer,
    Coach: CoachReducer,
    Config: ConfigReducer,
    Error: ErrorHandlerReducer,
    Fonts: FontReducer,
    GUI: GuiReducer,
    Login: LoginReducer,
    Modal: ModalReducer,
    Order: OrderReducer,
    Patterns: PatternReducer,
    PhoneVerification: PhoneVerificationReducer,
    Polling: PollingReducer,
    SavedOrders: SavedOrdersReducer,
    Session: SessionReducer,
    SubmittedOrders: SubmittedOrdersReducer,
    Thumb3D: Thumb3DReducer
  });

  const store:any = createStore(rootReducer);
  // Monitor the store so we know when to set the UI task for show welcome
  // and run auto save.
  initBackgroundUseCaseTriggers(store);
  monitorStore(store);

  const dispatch = function(data:any){
    console.log('dispatching...', data);
    store.dispatch(data);
  };

  const dispatchSuccess = function(data:any){
    data.resultType = UseCaseResultType.SUCCESS;
    dispatch(UseCaseResult(data));
  };

  const dispatchFailure = function(data:any){
    data.resultType = UseCaseResultType.FAILURE;
    dispatch(UseCaseResult(data));
  };

  const dispatchCancelled = function(data:any){
    data.resultType = UseCaseResultType.CANCELLED;
    dispatch(UseCaseResult(data));
  };

  const dispatchStart = function(data:any) {
    data.resultType = UseCaseResultType.START;
    let result = UseCaseResult( data );
    dispatch(result);
  };

  const dispatchUseCaseSuccess = function(useCase, data){
    dispatchSuccess({
      useCase: useCase
      , type: useCase.type
      , data: data
    });
  };

  const dispatchUseCaseFailure = function(useCase, reason){
    dispatchFailure({
      useCase: useCase
      , type: useCase.type
      , rejectionReason: reason
    });
  };

  const dispatchUseCaseCancelled = function(useCase, reason){
    dispatchCancelled({
      useCase: useCase
      , type: useCase.type
      , rejectionReason: reason
    });
  };

  const dispatchUseCaseStart = function(useCase, useCaseData?){
    dispatchStart({
      useCase: useCase
      , type: useCase.type
      , data: useCaseData
    });
  };

  return Object.freeze({

    dispatch(key: string, data: any){
      dispatchSuccess({ type: key, data: data });
    },

    getReduxStore(){
      return store;
    },

    //I don't like this idea, forcing UI to pass in list of use cases
    //Haven't really thought of anything better yet...
    //You'll end up doing a bunch of work you don't need to.  Maybe doesn't matter though (performance wise)
    can(useCaseNames, state){
      let result = {};

      //Just for testing
      App_.each(useCaseNames, function(name){
        result[name] = true;
      });

      return result;
    },

    runUseCase(useCase: UseCase, options:any = {}){
      Object.assign({ dispatchStart: true }, options);

      if(useCase.checkConditions){
        let ruleResult = useCase.checkConditions(this.getReduxStore().getState(), options);
        if(!ruleResult.isAllowed){
          const showLoginPopup = ruleResult.failMessage === "CoachesKey not found" ? true : false;
          const popupObj = {
            title: showLoginPopup ? "Please, login to continue." : ruleResult.failMessage,
            showConfirm: showLoginPopup ? true : false,
            showCancel: showLoginPopup ? false : true,
            onClick: showLoginPopup ? showLogin : undefined
          }
          
          showCustomMsgPopup(popupObj);
          return;
        }
      }

      if (useCase.checkValidators){
        let ruleResult = useCase.checkValidators(options);
        if(!ruleResult.isAllowed){
          showCustomMsgPopup({
            title: ruleResult.failMessage,
            showCancel: true
          });
          return;
        }
      }

      if(options.dispatchStart !== false)
        dispatchUseCaseStart(useCase, options.dispatchStartData);

      try {
        return useCase.run(options)
        .then(function (useCaseResult:any = {}) {
          dispatchUseCaseSuccess(useCase, useCaseResult);
        }, function(reason){

          if(RejectionReason.was(reason, RejectKey.CANCELLED)){
            dispatchUseCaseCancelled(useCase, reason);
          }
          else{
            dispatchUseCaseFailure(useCase, reason);

            //Propagate failure, we aren't handling/recovering, just dispatching action failure.
            //Leave it up to individual use cases how they handle rejections.
            return Cx.reject(reason);
          }
        });
      }catch(error)
      {
        dispatchUseCaseFailure(useCase, error);
        return Cx.reject(error);
      }
    }
  });
}
