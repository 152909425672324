export default class global {
  public static __MAIN_CANVAS_ID__: string = 'main-canvas-el';
  public static _DEFAULT_DOC_ID_: string = '29150d88-b61a-4020-a77a-8edf704e7785';
  public static __DEFAULT_CAMERA_STATE__: string = '168.8591 102.4935 246.2264,-0.3944 0.5644 0.2191 XYZ,0.0000 0.0000 0.0000';
  public static __INVERTED_CAMERA_STATE__: string = '-174.1892 113.0994 -237.7234,-2.6975 -0.5845 -2.8849 XYZ,0.0000 0.0000 0.000';
  public static __MAIN_CANVAS_HEIGHT : number = 500 ;
  public static __NAMENUMBER_COLOR_PANEL_: string = 'name-number-color-panel';
  public static _SNAPSHOT_THUMB_SIZE_: number = 512 ;
  // public static _LOGIN_CONFIRMATION_: string = 'https://cxcdndev.azureedge.net/ub2/edge/';
  public static _CADWORX_ANONYMOUS_COACH_: string = 'ABC123' ;
  public static _POLL_INTERVAL_: number = 2000 ; //ms
  public static _POLL_SERVER_INTERVAL_: number = 5000 ; //ms
  public static _POLL_TIMEOUT_: number = 180000 ;
  public static _LOCAL_CACHE_EXPIRE_DAYS_: number = 5 ;
  public static _PARTNER_KEY_:string = Cx.Config.app.pk;
  //assets configuration start
  public static _FONT_CONFIG_TAG_NAME_: string = '/config/font6';
  public static _FONT_CONFIG_NAME_: string = 'FontConfigPrivate6';
  public static _PATTERN_CONFIG_DATA_ID_: string = '71f95866-5f14-463a-bb26-f35f188721a0';
  public static _JERSEY_THUMB_CONFIG_DATA_ID_ : string = '33773e3d-fbac-424d-a339-f8769568600e';
  public static _FONT_OUTLINE_ : {} = {
    '0': 0.125,
    '1.5': 0.125,
    '2.5': 0.15625,
    '3.5': 0.1875,
    '4.5': 0.203125,
    '5.5': 0.21875,
    '6.5': 0.234375,
    '7.5': 0.25,
    '8.5': 0.265625,
    '9.5': 0.28125,
    '10.5': 0.296875,
    '11.5': 0.3125
  }
  public static DEFAULT_CA_FONT = 'Pro Narrow';
  public static DEFAULT_NAME_FONT = 'Pro Narrow';
  public static DEFAULT_NUMBER_FONT = 'VARSITY';
  public static _3D_THUMBS_PER_PAGE_ = 3;
  public static _3D_THUMBS_MAX_CONCURRENT_INSTANCES_ = 1;
  public static _3D_THUMB_RENDER_URL_ = 'http://cx-3d-render.dd.poweredbycadworx.com/render';
}
