import { getInDecorationAreaName, getNamedColorZones, getNameEffect, getOutlines } from '../../usecases/actions/canvas';
import { DesignElement } from './DesignElement';
import { FillTarget } from './FillTarget';
import { Outline } from './Outline';
import { fillTargetIsOutline, OutlineFillTarget } from './OutlineFillTarget';
import { fillTargetIsText, TextFillTarget } from './TextFillTarget';
import global from '../../../gui/services/global'
import { getInitialFillTarget } from '../../usecases/actions/getInitialFillTarget';

export interface TextDesignElement extends DesignElement {
  brush?: Cx.Brush;
  effect?: string;
  fontFamily?: string;
  fontIsScript?: boolean;
  fontId?: string;
  fontSize?: number;
  outlines?: Outline[];
  outlineJoin?: string;
  spacing?: number;
  text?: string;
  outlineProportions?: number[];
  hidden?: boolean;
};

// User-Defined Type Guard
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
export function isTextDesignElement(designElement: DesignElement): designElement is TextDesignElement {
  return (designElement as TextDesignElement).type === 'TEXT';
}

// Clone helper

export function cloneTextDesignElement(props: TextDesignElement): TextDesignElement {
  const clone = { ...props };

  if (props.brush) {
    clone.brush = props.brush.clone();
  }

  if (props.outlines) {
    clone.outlines = [...props.outlines];
  }

  if (props.outlineProportions) {
    clone.outlineProportions = [...props.outlineProportions]
  }

  return clone;
}

// Default TS Black/TS White Color 
export function getBlackColorBrush() {
  const fillTarget = getInitialFillTarget(global.__MAIN_CANVAS_ID__);

  // Check if fillTarget.colorZone is undefined or null
  if (!fillTarget?.colorZone || !fillTarget.colorZone.Value) {
    const color = Cx.Color.Black();
    color.spotColorName = "TS Black";
    color.name = "Black";
    color._spotColorName = "TS Black";
    color._name = "Black";
    return new Cx.Brush({ color: color });
  }

  if (fillTarget.colorZone.Value._link) {
    return fillTarget.colorZone.Value;
  }

  const defaultColor = fillTarget.colorZone.Value.color?.rgb;
  const isBlackDefault = defaultColor === '000000';

  if (isBlackDefault) {
    const color = Cx.Color.White();
    color.spotColorName = "TS White";
    color.name = "White";
    color._spotColorName = "TS White";
    color._name = "White";
    return new Cx.Brush({ color: color });
  } else {
    const color = Cx.Color.Black();
    color.spotColorName = "TS Black";
    color.name = "Black";
    color._spotColorName = "TS Black";
    color._name = "Black";
    return new Cx.Brush({ color: color });
  }
}


// Default CA helper

export function defaultCADesignElement(props: TextDesignElement): TextDesignElement {
  return Object.assign({}, {
    brush: getBlackColorBrush(),
    effect: 'None',
    fontFamily: global.DEFAULT_NAME_FONT,
    fontSize: 63.5, // 2.5 inches
    fontIsScript: false,
    hidden: false,
    outlineJoin: 'Miter', // [ 'Miter', 'Bevel', 'Round', 'MiterClipped', 'AdaptiveMiter' ]
    outlineProportions: [],
    outlines: [],
    spacing: 0,// +(63.5 * 0.06575).toFixed(2),
    text: 'C',
    type: 'TEXT'
  }, props);
}

// Default name helper

export function defaultNameDesignElement(props: TextDesignElement): TextDesignElement {
  return Object.assign({}, {
    brush: getBlackColorBrush(),
    effect: 'None',
    fontFamily: global.DEFAULT_NAME_FONT,
    fontSize: 63.5,
    fontIsScript: false,
    hidden: false,
    outlines: [],
    outlineProportions: [],
    outlineJoin: 'Miter', //[ 'Miter', 'Bevel', 'Round', 'MiterClipped', 'AdaptiveMiter' ]
    spacing: 0,//+(63.5 * 0.06575).toFixed(2),
    text: 'ANY NAME',
    type: 'TEXT'
  }, props);
}

// Default back number helper

export function defaultBackNumberDesignElement(props: TextDesignElement): TextDesignElement {
  return Object.assign({}, {
    brush: getBlackColorBrush(),
    effect: 'None',
    fontFamily: global.DEFAULT_NUMBER_FONT,
    fontSize: 203.2,
    fontIsScript: false,
    hidden: false,
    outlines: [],
    outlineProportions: [],
    outlineJoin: 'Miter', //[ 'Miter', 'Bevel', 'Round', 'MiterClipped', 'AdaptiveMiter' ]
    spacing: 0,
    text: '00',
    type: 'TEXT'
  }, props);
}

// Default sleeve number helper

export function defaultSleeveNumberDesignElement(props: TextDesignElement): TextDesignElement {
  return Object.assign({}, {
    brush: getBlackColorBrush(),
    effect: 'None',
    fontFamily: global.DEFAULT_NUMBER_FONT,
    fontSize: 101.6,
    fontIsScript: false,
    hidden: false,
    outlines: [],
    outlineProportions: [],
    outlineJoin: 'Miter', //[ 'Miter', 'Bevel', 'Round', 'MiterClipped', 'AdaptiveMiter' ]
    spacing: 0,
    text: '00',
    type: 'TEXT'
  }, props);
}

export function defaultRibNumberDesignElement(props: TextDesignElement): TextDesignElement {
  return Object.assign({}, {
    brush: getBlackColorBrush(),
    effect: 'None',
    fontFamily: global.DEFAULT_NUMBER_FONT,
    fontSize: 101.6,
    fontIsScript: false,
    hidden: false,
    outlines: [],
    outlineProportions: [],
    outlineJoin: 'Miter', //[ 'Miter', 'Bevel', 'Round', 'MiterClipped', 'AdaptiveMiter' ]
    spacing: 0,
    text: '00',
    type: 'TEXT'
  }, props);
}

// Default main logo as text helper

export function defaultMainLogoAsTextDesignElement(props: TextDesignElement): TextDesignElement {
  return Object.assign({}, {
    brush: getBlackColorBrush(),
    effect: 'None',
    fontFamily: global.DEFAULT_NAME_FONT,
    fontSize: 63.5, // 2.5 inches
    fontIsScript: false,
    hidden: false,
    outlines: [],
    outlineProportions: [],
    outlineJoin: 'Miter', //[ 'Miter', 'Bevel', 'Round', 'MiterClipped', 'AdaptiveMiter' ]
    spacing: 0,//+(63.5 * 0.06575).toFixed(2),
    text: 'ANY NAME',
    type: 'TEXT'
  }, props);
}

// Get main logo as text from design element

export function getMainLogoAsTextDesignElement(designElement: DesignElement) {
  // If it is not already text
  // Then return as text
  return (!isTextDesignElement(designElement))
    ?
    defaultMainLogoAsTextDesignElement({
      canvasId: designElement.canvasId,
      decorationAreaId: designElement.decorationAreaId
    })
    :
    designElement;
}

// To figure helper

export function textDesignElementToFigure(props: TextDesignElement) {
  // @ts-ignore TODO: Text does not exist on Figure
  let textFigure = Cx.Figure.Text({
    // @ts-ignore TODO: Can't create instance of abstract class
    brush: props.brush,
    // @ts-ignore
    extraCharSpacing: new Cx.Magnitude(props.spacing * 25.4),// {value: props.spacing, type: "mm"}, 
    // @ts-ignore TODO: Can't create instance of abstract class
    font: new Cx.Font({ familyName: props.fontFamily, id: props.fontId }),
    fontSize: props.fontSize,
    hidden: props.hidden,
    text: props.text
  });
  textFigure.metadata.set('outlineProportions', JSON.stringify(props.outlineProportions));
  textFigure.metadata.set('outlineJoin', props.outlineJoin);
  textFigure.metadata.set('fontIsScript', props.fontIsScript);
  return textFigure;
}

// From figure helper

export function textDesignElementFromFigure(canvasId: string, figure: Cx.TextFigure): TextDesignElement {
  const areaId = getInDecorationAreaName(figure);

  const element = {
    canvasId,
    brush: figure.brush,
    effect: getNameEffect(canvasId, areaId),
    // @ts-ignore
    fontFamily: figure.font.familyName,
    fontIsScript: figure.metadata.get('fontIsScript') == true,
    // @ts-ignore
    fontId: figure.font.id,
    // @ts-ignore
    fontSize: figure.fontSize,
    hidden: figure.hidden,
    outlines: getOutlines(canvasId, areaId),
    outlineProportions: figure.metadata.get('outlineProportions') ? JSON.parse(figure.metadata.get('outlineProportions')) : [],
    outlineJoin: figure.metadata.get('outlineJoin') ? figure.metadata.get('outlineJoin') : 'Miter',
    spacing: +(figure.extraCharSpacing.value / 25.4).toFixed(2),
    decorationAreaId: areaId,
    // @ts-ignore
    text: figure.text,
    type: 'TEXT'
  } as TextDesignElement;

  return element;
}

// Update from text helper

// @ts-ignore
export function updateTextDesignElementFromFigure(props: TextDesignElement, figure: Cx.TextFigure): TextDesignElement {
  const clone = cloneTextDesignElement(props);
  const canvasId = clone.canvasId;
  // const designArea = clone.decorationAreaId;

  // Important to return a new object
  return Object.assign({}, clone, textDesignElementFromFigure(canvasId, figure));
}

// Has fill target helper

export function textDesignElementHasFillTarget(designElement: DesignElement, target: FillTarget): boolean {
  const t = target as TextFillTarget;

  return fillTargetIsText(t) &&
    designElement.canvasId === t.canvasId &&
    designElement.decorationAreaId === t.designArea ? true : false;
}

// Outline has fill target helper

export function textDesignElementHasOutlineFillTarget(designElement: DesignElement, target: FillTarget): boolean {
  const t = target as OutlineFillTarget;

  return fillTargetIsOutline(t) &&
    designElement.canvasId === t.canvasId &&
    designElement.decorationAreaId === t.designArea ? true : false;
}