import { coachHasSessionUseCase } from '../usecases/coachHasSession';
import { signOutUseCase } from '../usecases/signOut'
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { verifyPhoneCodeUseCase } from '../usecases/login/verifyPhoneCode';
import { verifyEmailHashUseCase } from '../usecases/login/verifyEmail';
import { reportMessage } from '../../gui/util/rollbar';
export interface CoachState {
  cadworxSessionId: string;
  coachesKey: string;
  ddAccountNumber: string;
  getSessionFailed: boolean;
  hasSession: boolean;
  id: string;
  verificationError: any
}

const initState: CoachState = {
  cadworxSessionId: undefined,
  coachesKey: undefined,
  ddAccountNumber: undefined,
  getSessionFailed: false,
  hasSession: false,
  id: undefined,
  verificationError: undefined
};

export function CoachReducer (state: CoachState = initState, actionResult: UseCaseResult): CoachState {

  switch (actionResult.type) {

    case coachHasSessionUseCase.type:
    case verifyPhoneCodeUseCase.type:
    case verifyEmailHashUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason);;
        return Object.assign({}, state, {
          getSessionFailed: true,
          hasSession: false,
          verificationError: actionResult.rejectionReason
        });
      }

      else if (actionResult.success()) {
        const coach = actionResult.data.coach;

        return Object.assign({}, state, {
          cadworxSessionId: coach.cadworxSessionId,
          coachesKey: coach.coachesKey,
          ddAccountNumber: coach.ddAccountNumber,
          getSessionFailed: false,
          hasSession: true,
          id: coach.id,
          verifycationError: undefined
        });
      }

      return state;

    case signOutUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        return Object.assign({}, state, initState);
      }

      return state ;

  }

  return state;

}
